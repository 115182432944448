@font-face {
  font-family: 'Outfit';
  src: local('Outfit'),
    url(./assets/fonts/outfit/fonts/webfonts/Outfit-SemiBold.woff) format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Outfit, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.slick-prev:before,
.slick-next:before {
  color: #00dcf0 !important;
  font-size: 18px !important;
  z-index: 2000;
  display: none !important;
}

@media only screen and (min-width: 766px) {

  .slick-prev:before,
  .slick-next:before {
    color: #00dcf0 !important;
    font-size: 40px !important;
    z-index: 2000;
  }
}

.background-video {
  /* width: 100%; */
  width: 100vw;
  height: 70vh;
  /* object-fit: cover; */
  /* position: absolute; */
  left: 0;
  right: 0;
  top: 0;
  /* bottom: 0; */
  z-index: 1;
}

@keyframes swing {

  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

.pro-sidebar {
  color: #767676;
  /* color: #00dcf0; */
  height: 100%;
  width: 250px;
  min-width: 250px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  overflow: hidden;
}

.pro-sidebar>.pro-sidebar-inner {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
  height: 100%;
  position: relative;
  z-index: 101;
  /* border-radius: 12px; */
  box-shadow: 0px 0px 14px rgba(186, 186, 186, 0.25);
}

.pro-sidebar>.pro-sidebar-inner>img.sidebar-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  opacity: 0.3;
  left: 0;
  top: 0;
  z-index: 100;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
  /* border-bottom: 1px solid #00dcf0; */
  /* border-bottom: 1px solid #ccc; */
  padding-top: 26px;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
  flex-grow: 1;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}

.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

@media (max-width: 768px) {
  .pro-sidebar.collapsed {
    width: 0;
    min-width: 0;
  }
}

.pro-sidebar.rtl {
  text-align: right;
  direction: rtl;
}

@media (max-width: 480px) {
  .pro-sidebar.xs {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.xs.collapsed {
    left: -80px;
  }

  .pro-sidebar.xs.toggled {
    left: 0;
  }

  .pro-sidebar.xs.toggled .overlay {
    display: block;
  }

  .pro-sidebar.xs.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.xs.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.xs.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 576px) {
  .pro-sidebar.sm {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.sm.collapsed {
    left: -80px;
  }

  .pro-sidebar.sm.toggled {
    left: 0;
  }

  .pro-sidebar.sm.toggled .overlay {
    display: block;
  }

  .pro-sidebar.sm.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.sm.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.sm.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 768px) {
  .pro-sidebar.md {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.md.collapsed {
    left: -80px;
  }

  .pro-sidebar.md.toggled {
    left: 0;
  }

  .pro-sidebar.md.toggled .overlay {
    display: block;
  }

  .pro-sidebar.md.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.md.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.md.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 992px) {
  .pro-sidebar.lg {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.lg.collapsed {
    left: -80px;
  }

  .pro-sidebar.lg.toggled {
    left: 0;
  }

  .pro-sidebar.lg.toggled .overlay {
    display: block;
  }

  .pro-sidebar.lg.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.lg.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.lg.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1200px) {
  .pro-sidebar.xl {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.xl.collapsed {
    left: -80px;
  }

  .pro-sidebar.xl.toggled {
    left: 0;
  }

  .pro-sidebar.xl.toggled .overlay {
    display: block;
  }

  .pro-sidebar.xl.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.xl.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.xl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1600px) {
  .pro-sidebar.xxl {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.xxl.collapsed {
    left: -80px;
  }

  .pro-sidebar.xxl.toggled {
    left: 0;
  }

  .pro-sidebar.xxl.toggled .overlay {
    display: block;
  }

  .pro-sidebar.xxl.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.xxl.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.xxl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: '';
  display: inline-block;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid #2b2b2b;
  /* border-radius: 50%; */
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad,
    -1px 0px 0px #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  position: relative;
  background-color: #fff;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
}

.pro-sidebar .pro-menu a:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.pro-sidebar .pro-menu a:hover {
  /* color: #00052e; */
  color: #EF5DA8;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #00dcf0;
}

.pro-sidebar .pro-menu .pro-menu-item.active a {
  color: #00dcf0;
  font-weight: 700;
}

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
  display: flex;
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 22px 15px 22px;
  cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
  outline: none;
  color: #00dcf0;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  margin-right: 3px;
  font-size: 14px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
}

.pro-sidebar .pro-menu .pro-menu-item.active>.pro-inner-item:hover>.pro-icon-wrapper {
  color: #00dcf0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  /* color: #00052e; */
  color: #EF5DA8;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item.active:hover .pro-icon-wrapper .pro-icon {
  /* animation: swing ease-in-out 0.5s 1 alternate; */
  color: #00dcf0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item:before {
  background: #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: #adadad;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  vertical-align: middle;
  transition: transform 0.3s;
  transform: rotate(-45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item:before {
  background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 40px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
  background: none;
  width: auto;
  min-width: auto;
  height: auto;
  line-height: auto;
}

.pro-sidebar .pro-menu:not(.inner-submenu-arrows) .pro-inner-list-item .pro-menu-item.pro-sub-menu .pro-inner-item .pro-arrow-wrapper {
  display: none;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  /* background-color: #2b2b2b; */
}

.pro-sidebar .pro-menu.square .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  border-radius: 0;
}

.pro-sidebar .pro-menu.round .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  /* border-radius: 4px; */
}

.pro-sidebar .pro-menu.circle .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  /* border-radius: 50%; */
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item {
  position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.suffix-wrapper,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.prefix-wrapper {
  opacity: 0;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-list-item {
  background-color: #fff;
  z-index: 11111;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu {
  position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item {
  pointer-events: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  display: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
  height: auto !important;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
  padding-left: 10px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  padding-left: 20px;
  /* border-radius: 4px; */
  z-index: 1000;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item {
  transition: visibility, transform 0.3s;
  visibility: visible;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
  /* animation: swing ease-in-out 0.5s 1 alternate; */
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 8px 20px 8px 35px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  margin-right: 0;
  margin-left: 10px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  right: auto;
  left: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(135deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 24px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 15px 8px 30px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  margin-right: 0;
  margin-left: 15px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
  padding-right: 10px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  padding-left: 0;
  padding-right: 20px;
  z-index: 10000;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px;
}

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^='left']>.popper-arrow {
  right: 0;
  border-right: 7px solid #2b2b2b;
}

.popper-element[data-popper-placement^='right']>.popper-arrow {
  left: 0;
  border-left: 7px solid #2b2b2b;
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}